import React, { memo, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AppBar,
  Theme,
  Toolbar,
  MenuItem,
  SwipeableDrawer,
  IconButton,
  Divider,
  Avatar,
  ListItemIcon,
} from '@material-ui/core';
import {
  DateRange,
  VideocamOutlined,
  AccountCircle,
  ExitToApp,
  Settings,
  Menu,
  Help,
  ExpandMore,
  Call,
} from '@material-ui/icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  withStyles,
  WithStyles,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';

import clsx from 'clsx';
import { Dropdown } from '../../Dropdown';
import { Logo } from '../../../assets/icons';
import { Typography } from '../../Typography';
import { IconOnlyButton } from '../../Button';
import AppBarDrawer from './AppBarDrawer';
import { getRole } from '../../../features/settings/components/profileSettings.view';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      boxShadow: 'none',
      backgroundColor: theme.palette.common.white,
      '& .MuiToolbar-gutters': {
        padding: '0 36px',
        [theme.breakpoints.down('xs')]: {
          padding: '0 24px',
        },
      },
      '& .MuiMenuItem-root': {
        borderRadius: 4,
        '&:hover': {
          background: theme.custom.primaryGradient,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        },
      },
      [theme.breakpoints.down('xs')]: {
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 1000,
      },
    },
    primaryColorText: {
      background: theme.custom.primaryGradient,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontWeight: 600,
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
    },
    itemsWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    largeView: {
      display: 'flex',
      flexGrow: 1,
    },
    menuButtonWrapper: {
      flexGrow: 1,
      textAlign: 'right',
    },
    avatarButton: {
      '& .MuiIconButton-root': {
        padding: 0,
        border: 'none',
      },
    },
    leftWrapper: {
      display: 'flex',
      flexGrow: 1,
      color: theme.palette.secondary.main,
    },
    logo: {
      height: 40,
      marginRight: '3vw',
    },
    avatar: {},
    userName: {
      textTransform: 'capitalize',
      color: theme.palette.grey[500],
    },
    profileDropdown: {
      padding: '16px 0 8px',
    },
    dropdownIcon: {
      marginLeft: 5,
    },
    profileWrapper: {
      display: 'flex',
      gap: '8px',
      padding: '0 20px',
      textTransform: 'capitalize',
      marginBottom: '18px',
      width: '200px',
      '& h5': {
        lineHeight: '18px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& span': {
        textTransform: 'capitalize',
        color: theme.palette.grey[700],
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    logoutButton: {
      background: theme.custom.primaryGradient,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      marginTop: '8px',
      '& .MuiSvgIcon-root': {
        fill: theme.palette.primary.main,
      },
    },
  });
};

export interface IAppBarProps extends WithStyles<typeof styles> {
  auth: {
    user: any;
    permissions: any;
  };
  settings: {
    data: {
      general: {
        logo: string;
      };
    };
  };
  signOut: () => void;
  scheduleMeetingOnClick: () => void;
  instantMeetingOnClick: () => void;
}

const AppBarView = ({
  classes,
  auth,
  settings,
  signOut,
  scheduleMeetingOnClick,
  instantMeetingOnClick,
}: IAppBarProps) => {
  const history = useHistory();
  const location = useLocation();

  const [mtnAnchorEl, setMtnAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [reportingAnchorEl, setReportingAnchorEl] = useState(null);
  const [streamingAnchorEl, setStreamingAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { user, permissions } = auth;

  const toggleMeetingMenu = (event: any) => {
    setMtnAnchorEl(event?.currentTarget);
  };

  const toggleProfile = (event: any) => {
    setProfileAnchorEl(event?.currentTarget);
  };

  const toggleReporting = (event: any) => {
    setReportingAnchorEl(event?.currentTarget);
  };

  const toggleStreaming = (event: any) => {
    setStreamingAnchorEl(event?.currentTarget);
  };

  const getSelectedMenuItem = (route: string) => {
    const currentPath = location.pathname.toString();
    const targetPath = route.toString();

    // check for root path
    if (currentPath === targetPath) {
      return true;
    }

    return false;
  };

  const menuOptions = [
    {
      name: intl.formatMessage({
        id: 'overview',
      }),
      id: 'overview',
      route: '/overview',
      onClick: () => history.push('/overview'),
      selected: getSelectedMenuItem('/overview'),
    },
    {
      name: intl.formatMessage({
        id: 'speed_dials_tab',
      }),
      id: 'speed_dials_tab',
      route: '/',
      onClick: () => history.push('/'),
      selected: getSelectedMenuItem('/'),
    },
  ];
  if (permissions.viewMeetings) {
    menuOptions.push({
      name: intl.formatMessage({
        id: 'meetings',
      }),
      id: 'meetings',
      route: '/meetings',
      onClick: () => history.push('/meetings'),
      selected: getSelectedMenuItem('/meetings'),
    });
  }
  if (permissions.viewAdmins) {
    menuOptions.push({
      name: intl.formatMessage({
        id: 'admins',
      }),
      id: 'admins',
      route: '/admins',
      onClick: () => history.push('/admins'),
      selected: getSelectedMenuItem('/admins'),
    });
  }
  if (permissions.viewCustomers) {
    menuOptions.push({
      name: intl.formatMessage({
        id: 'customers',
      }),
      id: 'customers',
      route: '/customers',
      onClick: () => history.push('/customers'),
      selected: getSelectedMenuItem('/customers'),
    });
  }

  if (permissions.viewAllCustomers) {
    menuOptions.push({
      name: intl.formatMessage({
        id: 'customers',
      }),
      id: 'admin-customers',
      route: '/allcustomers',
      onClick: () => history.push('/allcustomers'),
      selected: getSelectedMenuItem('/allcustomers'),
    });
  }
  if (permissions.viewMembers) {
    menuOptions.push({
      name: intl.formatMessage({
        id: 'members',
      }),
      id: 'members',
      route: '/members',
      onClick: () => history.push('/members'),
      selected: getSelectedMenuItem('/members'),
    });
  }
  if (permissions.viewAllMembers) {
    menuOptions.push({
      name: intl.formatMessage({
        id: 'members',
      }),
      id: 'admin-members',
      route: '/allmembers',
      onClick: () => history.push('/allmembers'),
      selected: getSelectedMenuItem('/allmembers'),
    });
  }
  if (permissions.viewGroups) {
    menuOptions.push({
      name: intl.formatMessage({
        id: 'groups',
      }),
      id: 'groups',
      route: '/groups',
      onClick: () => history.push('/groups'),
      selected: getSelectedMenuItem('/groups'),
    });
  }
  if (permissions.viewAllDevices) {
    menuOptions.push({
      name: intl.formatMessage({
        id: 'groups',
      }),
      id: 'admin-groups',
      route: '/allgroups',
      onClick: () => history.push('/allgroups'),
      selected: getSelectedMenuItem('/allgroups'),
    });
  }
  if (permissions.viewDevices) {
    menuOptions.push({
      name: intl.formatMessage({
        id: 'devices',
      }),
      id: 'devices',
      route: '/devices',
      onClick: () => history.push('/devices'),
      selected: getSelectedMenuItem('/devices'),
    });
  }
  if (permissions.viewAllDevices) {
    menuOptions.push({
      name: intl.formatMessage({
        id: 'devices',
      }),
      id: 'admin-devices',
      route: '/alldevices',
      onClick: () => history.push('/alldevices'),
      selected: getSelectedMenuItem('/alldevices'),
    });
  }

  const meetingMenuList = [];

  if (permissions.scheduleMeeting) {
    meetingMenuList.push({
      label: intl.formatMessage({
        id: 'schedule_meeting',
      }),
      id: 'schedule-a-meeting',
      onClick: () => scheduleMeetingOnClick(),
      icon: <DateRange fontSize="small" />,
    });
  }

  if (permissions.instantMeeting) {
    meetingMenuList.push({
      label: intl.formatMessage({
        id: 'instant_meeting',
      }),
      id: 'instant-meeting',
      onClick: () => instantMeetingOnClick(),
      icon: <VideocamOutlined fontSize="small" />,
    });
  }

  const profileMenuList = [
    {
      label: intl.formatMessage({
        id: 'contact_us',
      }),
      id: 'contact-us',
      onClick: () => history.push('/contact-us'),
      icon: <Call fontSize="small" />,
    },
    {
      label: intl.formatMessage({
        id: 'help_support',
      }),
      id: 'help-support',
      onClick: () => history.push('/help'),
      icon: <Help fontSize="small" />,
    },
    {
      label: intl.formatMessage({
        id: 'settings',
      }),
      id: 'settings',
      onClick: () => history.push('/settings'),
      icon: <Settings fontSize="small" />,
    },
  ];

  const reportingMenuList = [
    {
      label: intl.formatMessage({
        id: 'meeting_reports',
      }),
      id: 'meeting-reports',
      onClick: () => history.push('/reports/meetings'),
      selected: getSelectedMenuItem('/reports/meetings'),
    },
  ];

  const streamingMenuList = [];

  if (permissions.viewLivestreamings) {
    streamingMenuList.push({
      label: intl.formatMessage({
        id: 'live_streamings',
      }),
      id: 'livestreaming',
      onClick: () => history.push('/livestreaming'),
      selected: getSelectedMenuItem('/livestreaming'),
    });
  }

  if (permissions.viewScheduledEvents) {
    streamingMenuList.push({
      label: intl.formatMessage({
        id: 'scheduled_events',
      }),
      id: 'scheduledevents',
      onClick: () => history.push('/scheduledevents'),
      selected: getSelectedMenuItem('/scheduledevents'),
    });
  }
  const DropDownProfile = () => {
    return (
      <div className={classes.profileWrapper}>
        <Avatar
          className={classes.avatar}
          alt={user?.profile?.name}
          src={user?.profile?.picture}
        />
        <div>
          <Typography variant="h5">{user?.profile?.name}</Typography>
          <Typography variant="overline">
            {intl.formatMessage({
              id: getRole(user?.role),
            })}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <>
      <AppBar className={classes.root} position="static" color="default">
        <Toolbar className={classes.toolbar}>
          <div className={clsx(classes.itemsWrapper, 'max-width-responsive')}>
            <img
              className={classes.logo}
              src={settings?.data?.general?.logo || Logo}
              alt="meeting-manager-logo"
            />
            {!isMobile && (
              <div className={classes.largeView}>
                <div className={classes.leftWrapper}>
                  {menuOptions.map((item) => (
                    <MenuItem
                      onClick={() => item.onClick()}
                      key={item.id}
                      className={
                        item.selected ? classes.primaryColorText : undefined
                      }
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                  {streamingMenuList.length > 0 && (
                    <MenuItem
                      onClick={(e) => toggleStreaming(e)}
                      className={
                        getSelectedMenuItem('/scheduledevents') ||
                        getSelectedMenuItem('/livestreaming')
                          ? classes.primaryColorText
                          : ''
                      }
                    >
                      <FormattedMessage id="streamings" />
                      <ExpandMore
                        fontSize="small"
                        color="inherit"
                        className={classes.dropdownIcon}
                      />
                    </MenuItem>
                  )}
                  {permissions.viewReports ? (
                    <MenuItem
                      onClick={(e) => toggleReporting(e)}
                      className={
                        getSelectedMenuItem('/reports/meetings')
                          ? classes.primaryColorText
                          : ''
                      }
                    >
                      <FormattedMessage id="reporting" />
                      <ExpandMore
                        fontSize="small"
                        color="inherit"
                        className={classes.dropdownIcon}
                      />
                    </MenuItem>
                  ) : null}
                </div>
                {permissions.scheduleMeeting || permissions.instantMeeting ? (
                  <MenuItem onClick={(e) => toggleMeetingMenu(e)}>
                    <FormattedMessage id="host_meeting" />
                    <ExpandMore
                      fontSize="small"
                      color="inherit"
                      className={classes.dropdownIcon}
                    />
                  </MenuItem>
                ) : null}
                <MenuItem onClick={(e) => toggleProfile(e)} disableGutters>
                  <Avatar
                    className={classes.avatar}
                    alt={user?.profile?.name}
                    src={user?.profile?.picture}
                  />
                </MenuItem>
              </div>
            )}
            {isMobile && (
              <div className={classes.menuButtonWrapper}>
                <IconOnlyButton
                  onClick={() => setDrawerOpen(!drawerOpen)}
                  customStyles={classes.avatarButton}
                >
                  <Avatar
                    className={classes.avatar}
                    alt={user?.profile?.name}
                    src={user?.profile?.picture}
                  />
                </IconOnlyButton>
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Dropdown
        id="app-bar-meeting-more-option-button"
        open={Boolean(mtnAnchorEl)}
        anchorRef={mtnAnchorEl}
        handleClose={() => toggleMeetingMenu(null)}
        itemList={meetingMenuList}
      />
      <Dropdown
        id="app-bar-profile-more-option-button"
        open={Boolean(profileAnchorEl)}
        anchorRef={profileAnchorEl}
        handleClose={() => toggleProfile(null)}
        itemList={profileMenuList}
        customStyles={classes.profileDropdown}
        topChildren={<DropDownProfile />}
        bottomChildren={
          <MenuItem className={classes.logoutButton} onClick={() => signOut()}>
            <ListItemIcon>
              <ExitToApp fontSize="small" />
            </ListItemIcon>
            <Typography variant="h6">
              {intl.formatMessage({
                id: 'sign_out',
              })}
            </Typography>
          </MenuItem>
        }
      />
      <Dropdown
        id="app-bar-reporting-button"
        open={Boolean(reportingAnchorEl)}
        anchorRef={reportingAnchorEl}
        handleClose={() => toggleReporting(null)}
        itemList={reportingMenuList}
      />
      <Dropdown
        id="app-bar-streaming-button"
        open={Boolean(streamingAnchorEl)}
        anchorRef={streamingAnchorEl}
        handleClose={() => toggleStreaming(null)}
        itemList={streamingMenuList}
      />
      {/* <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={false}
      >
        <MenuItem onClick={(e) => toggleProfile(e)}>
          <AccountCircle className={classes.avatar} />
          {user?.profile?.name}
        </MenuItem>
        {menuOptions.map((item) => (
          <MenuItem onClick={() => item.onClick()}>{item.name}</MenuItem>
        ))}
      </Drawer> */}
      <SwipeableDrawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
      >
        <AppBarDrawer
          auth={auth}
          menuOptions={menuOptions}
          signOut={signOut}
          meetingMenuList={meetingMenuList}
        />
        {/* <div className={classes.drawer}>
          <div>
            <MenuItem className={classes.drawerAvatarWrapper} disableGutters>
              <Avatar
                className={classes.avatar}
                alt={user?.profile?.name}
                src={user?.profile?.picture}
              />
              <div>
                <Typography
                  variant="subtitle1"
                  className={classes.drawerUserName}
                >
                  {filterFirstName(user?.profile?.name)}
                </Typography>
                <Typography variant="body2" className={classes.drawerUserRole}>
                  Administrator
                </Typography>
              </div>
            </MenuItem>
            {menuOptions.map((item) => (
              <MenuItem
                onClick={() => item.onClick()}
                key={item.id}
                disableGutters
              >
                {item.name}
              </MenuItem>
            ))}
            {meetingMenuList.map((item) => (
              <MenuItem onClick={item.onClick} key={item.id} disableGutters>
                {item.label}
              </MenuItem>
            ))}
            <MenuItem
              disableGutters
              onClick={reportingMenuList[0].onClick}
              id={reportingMenuList[0].id}
            >
              {reportingMenuList[0].label}
            </MenuItem>
          </div>
          <div>
            {profileMenuList.map((item) => (
              <MenuItem onClick={item.onClick} key={item.id} disableGutters>
                {item.label}
              </MenuItem>
            ))}
          </div>
        </div> */}
      </SwipeableDrawer>
    </>
  );
};

export default memo(withStyles(styles)(AppBarView));
